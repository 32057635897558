import { Container, Typography } from "@mui/material";
import { yellow, grey } from "@mui/material/colors";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

export default function WarningMsg({ sx, text }) {
  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        width: "max-content",
        bgcolor: yellow[300],
        color: grey[800],
        p: "3px 6px",
        gap: "3px",
        borderRadius: "10px",
        m: 0,
        ...sx,
      }}
      disableGutters
    >
      <WarningAmberIcon fontSize="small" />
      <Typography variant="body2" sx={{ fontWeight: "500" }}>
        {text}
      </Typography>
    </Container>
  );
}
