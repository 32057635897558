import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import LanguagePicker from "./LanguagePicker";
import SimPackagesMenu from "./SimPackagesMenuDesktop";
import Logo from "./Logo";
import MenuMobile from "./MenuMobile";
import MenuDesktop from "./MenuDesktop";
import ROUTES from "../../routes";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import PurchaseSimModule from "./PurchaseSimModule";
import { useSharedContext } from "../../SharedContext";
import ShoppingCart from "./ShoppingCart";

export default function NavBar() {
  const { isRTL } = useSharedContext();
  const theme = useTheme();
  const [showDropdown, setShowDropdown] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [open, setOpen] = useState(false);

  const openModal = () => setOpen(true);

  const navItems = [
    { key: "home", route: ROUTES.HOME },
    { key: "packages" },
    // { key: "purchase_sim", module: openModal },
    { key: "contact_us", route: ROUTES.CONTACT },
  ];

  const handleMouseEnter = () => {
    if (timeoutId !== null) clearTimeout(timeoutId);
    if (!showDropdown) {
      const timeoutId = setTimeout(() => setShowDropdown(true), 300);
      setTimeoutId(timeoutId);
    }
  };

  const handleMouseLeave = (instant) => {
    if (timeoutId !== null) clearTimeout(timeoutId);
    if (instant) setShowDropdown(false);
    else {
      const timeoutId = setTimeout(() => setShowDropdown(false), 500);
      setTimeoutId(timeoutId);
    }
  };

  return (
    <>
      <PurchaseSimModule open={open} setOpen={setOpen} />
      <AppBar
        sx={{
          background: "var(--navbar-color)",
          boxShadow:
            "0px -4px 5px rgba(0, 0, 0, 0.85), 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
          backdropFilter: "blur(5px)",
          position: "sticky",
          mt: "-50px",
          zIndex: 3,
          top: 0,
        }}
        position="static"
      >
        <Container maxWidth="xxl">
          <Toolbar sx={{ minHeight: "50px !important", flexDirection: isRTL ? "unset" : "row-reverse" }} disableGutters>
            <Logo
              sx={{
                display: { xs: "flex", sm: "none" },
                [isRTL ? "marginRight" : "marginLeft"]: "14px",
              }}
            />
            {/* <UserMenu /> */}
            <ShoppingCart />
            <LanguagePicker />
            <MenuDesktop navItems={navItems} handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} />
            <MenuMobile navItems={navItems} />
            <Logo
              sx={{
                display: { xs: "none", sm: "flex" },
                [theme.breakpoints.between("sm", "lg")]: {
                  position: "absolute",
                  transform: "translateX(-50%)",
                  left: "50%",
                },
              }}
            />
          </Toolbar>
        </Container>
      </AppBar>
      <SimPackagesMenu
        showDropdown={showDropdown}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
      />
    </>
  );
}
