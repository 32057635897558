import { Box } from "@mui/material";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Navigate({ to, children, noReplace = false, withDecoration = false, sx }, ref) {
  const { i18n } = useTranslation();
  const toPath = noReplace ? to : to.replace(":lang", i18n.language);
  return (
    <Box
      ref={ref}
      component={Link}
      to={toPath}
      sx={
        withDecoration
          ? {
              color: "#1976d2",
              textDecorationColor: "rgba(25, 118, 210, 0.4)",
              "&:hover": { textDecorationColor: "inherit" },
              ...sx,
            }
          : { display: "contents", color: "inherit", ...sx }
      }
    >
      {children}
    </Box>
  );
}

export default forwardRef(Navigate);
