import { Button, Container, TextField, Typography } from "@mui/material";
import CenteredContainer from "../CenteredContainer";
import { grey } from "@mui/material/colors";
import PackageInfo from "./PackageInfo";
import PhoneNumberValidator from "./PhoneNumberValidator";
import PartnerButton from "../PartnerButton";
import { useSharedContext } from "../../../SharedContext";
import UnlimitedSurfing from "./UnlimitedSurfing";
import WarningMsg from "./WarningMsg";

export default function PackageCardVertical({ title, title_color, package_info, isTopUp, isTopPackages = false, sx }) {
  const { t, isRTL, setOpenCartModal, cartItems, setCartItems, operators } = useSharedContext();
  const matching_operator = operators.find((x) => x.name === package_info.operator_name);

  const addPackageToCart = () => {
    setCartItems([...cartItems, { package_id: package_info.id, operator_name: package_info.operator_name }]);
    setOpenCartModal(true);
  };

  return (
    package_info && (
      <Container
        sx={{ width: "260px", display: "flex", flexDirection: "column", position: "relative", ...sx }}
        disableGutters
      >
        {package_info.is_unlimited_surfing === 1 && (
          <UnlimitedSurfing
            sx={{ position: "absolute", left: "50%", transform: "translate(-50%, 0%)", top: "-14px" }}
          />
        )}
        <CenteredContainer
          sx={{
            minHeight: "65px",
            bgcolor: title_color,
            paddingX: "5px !important",
          }}
        >
          {title}
        </CenteredContainer>
        <CenteredContainer sx={{ height: "90px" }}>
          <img
            src={`/images/operators/no_background/${package_info.operator_name}.webp`}
            alt={t(package_info.operator_name)}
            style={{
              maxWidth: matching_operator.maxWidth,
              height: "auto",
              width: "auto",
            }}
          />
        </CenteredContainer>
        <Container
          sx={{
            ...(isTopPackages && {
              "@media (min-width: 605px)": {
                minHeight: "164px",
                display: "flex",
                alignItems: "center",
              },
            }),
          }}
          disableGutters
        >
          <PackageInfo
            variant="body1"
            iconSize="1.25em"
            gap="6px"
            package_info={package_info}
            sx={{ "& .remarkText": { [isRTL ? "marginRight" : "marginLeft"]: "25px" } }}
          />
          {"warning" in package_info && (
            <WarningMsg sx={{ mt: "10px", width: "fit-content", gap: "5px" }} text={package_info.warning} />
          )}
        </Container>
        <Container sx={{ borderBottom: `2px dashed ${grey[500]}`, marginY: "20px" }} disableGutters />
        <Container sx={{ display: "flex", justifyContent: "center", marginBottom: "5px" }}>
          <Typography variant="h4" sx={{ fontWeight: "bold", direction: "ltr" }}>
            ₪ {package_info.price}
          </Typography>
        </Container>
        {isTopUp ? (
          <Container sx={{ display: "flex", direction: "ltr" }} disableGutters>
            <PhoneNumberValidator
              textField={
                <TextField
                  label={t("phoneNumber")}
                  variant="outlined"
                  sx={{
                    "& .MuiInputBase-root": {
                      borderRadius: "4px 0px 0px 4px",
                    },
                  }}
                />
              }
              button={
                <Button sx={{ borderRadius: "0px 4px 4px 0px", height: "43px" }} variant="contained">
                  {t("charge")}
                </Button>
              }
              package_id={package_info.id}
            />
          </Container>
        ) : (
          <PartnerButton onClick={addPackageToCart}>{t("choosePackage")}</PartnerButton>
        )}
      </Container>
    )
  );
}
