import { Container, Grid, Typography } from "@mui/material";
import { useSharedContext } from "../../SharedContext";
import { Trans } from "react-i18next";
import Navigate from "../Common/Navigate";
import ROUTES from "../../routes";

export default function Footer() {
  const { t, isRTL, operators } = useSharedContext();

  return (
    <Container
      sx={{
        bgcolor: "var(--navbar-color)",
        bottom: 0,
        py: 2,
        direction: isRTL ? "rtl" : "ltr",
      }}
      maxWidth="false"
    >
      <Grid
        container
        spacing={2}
        justifyContent="space-around"
        sx={{
          "& .MuiTypography-root": { color: "white" },
          "& a": { textDecoration: "unset", "& .MuiTypography-root:hover": { color: "var(--text-hover-color)" } },
          "& .MuiGrid-root": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "3px",
          },
        }}
      >
        <Grid item xs={12} md={4}>
          <Typography variant="h5">
            <Trans i18nKey="simChargeTitle" values={{ operator_sim: "" }} components={{ b: <b /> }} />
          </Typography>
          {operators.map((operator) => (
            <Navigate key={operator.name} to={ROUTES.TOP_UP_PACKAGES.replace(":operator_name", operator.name)}>
              <Typography>
                {t("chargeit")} <b>{t(`${operator.name}_sim`)}</b>
              </Typography>
            </Navigate>
          ))}
        </Grid>

        <Grid item xs={12} md={4} style={{ display: "none" }}>
          <Typography variant="h5">{t("purchase_sim")}</Typography>
          {operators.slice(0, 6).map((operator) => (
            <Navigate key={operator.name} to={ROUTES.SIM_ORDER_PACKAGES.replace(":operator_name", operator.name)}>
              <Typography>
                <Trans
                  i18nKey="simCard"
                  values={{ operator_sim: t(`${operator.name}_sim`) }}
                  components={{ b: <b /> }}
                />
              </Typography>
            </Navigate>
          ))}
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="h5">{t("zolsimWebsite")}</Typography>
          <Navigate to={ROUTES.CONTACT}>
            <Typography>{t("contact_us")}</Typography>
          </Navigate>
          <Navigate to={ROUTES.ACCESSIBILITY}>
            <Typography>{t("accessibilityStatement")}</Typography>
          </Navigate>
          <Navigate to={ROUTES.PRIVACY}>
            <Typography>{t("privacyPolicy")}</Typography>
          </Navigate>
          <Navigate to={ROUTES.TERMS}>
            <Typography>{t("termsOfUse")}</Typography>
          </Navigate>
        </Grid>
      </Grid>
    </Container>
  );
}
