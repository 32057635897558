import { Container, TextField, Typography } from "@mui/material";
import PackageInfo from "./PackageInfo";
import { grey } from "@mui/material/colors";
import UnlimitedSurfing from "./UnlimitedSurfing";
import PartnerButton from "../PartnerButton";
import PhoneNumberValidator from "./PhoneNumberValidator";
import { useSharedContext } from "../../../SharedContext";
import WarningMsg from "./WarningMsg";

export default function PackageCardHorizontal({ package_info, isTopUp, sx }) {
  const { t, isRTL, setOpenCartModal, cartItems, setCartItems } = useSharedContext();

  const addPackageToCart = () => {
    setCartItems([...cartItems, { package_id: package_info.id, operator_name: package_info.operator_name }]);
    setOpenCartModal(true);
  };

  return (
    package_info && (
      <Container
        sx={{
          borderRadius: "30px",
          bgcolor: "var(--package-card-color)",
          pt: "25px",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          ...sx,
        }}
        disableGutters
      >
        <Container
          sx={{
            transform: "translate(0, 0)",
            display: "flex",
            position: "absolute",
            left: "50%",
            transform: "translate(-50%, 0%)",
            top: "-14px",
            gap: "10px",
            paddingInlineStart: "35px",
          }}
          disableGutters
        >
          {package_info.is_unlimited_surfing === 1 && <UnlimitedSurfing />}
          {"warning" in package_info && (
            <WarningMsg
              sx={{
                [isRTL ? "margin-right" : "margin-left"]: "auto",
                [isRTL ? "margin-left" : "margin-right"]: "35px",
              }}
              text={package_info.warning}
            />
          )}
        </Container>

        <Typography variant="h5" align="center" sx={{ fontWeight: "bold" }}>
          {package_info.title}
        </Typography>
        <Container
          sx={{ position: "relative", display: "flex", alignItems: "center", overflow: "hidden" }}
          disableGutters
        >
          <img
            src={`/images/operators/with_background/${package_info.operator_name}.webp`}
            alt={t(package_info.operator_name)}
            style={{
              position: "relative",
              height: "250px",
              width: "250px",
              borderRadius: "30px",
              bottom: "-60px",
              right: "15px",
              transform: "rotate(-15deg)",
              transformOrigin: "center bottom",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
              marginLeft: "70px",
            }}
          />
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "fit-content",
              marginX: "unset",
              px: "70px !important",
            }}
          >
            <Typography align="center" variant="h5" sx={{ fontWeight: "bold", marginBottom: "7px", direction: "ltr" }}>
              ₪ {package_info.price}
            </Typography>
            {isTopUp ? (
              <PhoneNumberValidator
                textField={
                  <TextField
                    label={t("phoneNumber")}
                    variant="outlined"
                    sx={{
                      width: "170px",
                      "& .MuiInputBase-root": {
                        borderRadius: "4px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderWidth: "1px !important",
                      },
                    }}
                  />
                }
                button={<PartnerButton>{t("charge")}</PartnerButton>}
                package_id={package_info.id}
              />
            ) : (
              <PartnerButton sx={{ px: "10px", width: "max-content" }} onClick={addPackageToCart} variant="h6">
                {t("choosePackage")}
              </PartnerButton>
            )}
          </Container>
          <Container
            sx={{
              [isRTL ? "borderRight" : "borderLeft"]: `2px dashed ${grey[500]}`,
              minHeight: "200px",
              display: "flex",
              alignItems: "center",
            }}
            disableGutters
          >
            <PackageInfo
              variant="h6"
              iconSize="1.4em"
              gap="8px"
              package_info={package_info}
              sx={{ "& .remarkText": { [isRTL ? "marginRight" : "marginLeft"]: "30px" } }}
            />
          </Container>
        </Container>
      </Container>
    )
  );
}
