import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Container, Link } from "@mui/material";

export default function WhatsappWidget() {
  return (
    <Link target="_blank" rel="noopener" href="https://wa.me/972548341010">
      <Container
        sx={{
          position: "fixed",
          bottom: "13px",
          //Mobile
          right: "14px",
          width: "44px",
          height: "44px",
          //Desktop
          "@media (hover: hover) and (pointer: fine)": {
            right: "13px",
            width: "40px",
            height: "40px",
            "&:hover": { transform: "scale(1.1)" },
          },
          p: "4px",
          borderRadius: "100%",
          bgcolor: "#5DC861",
          cursor: "pointer",
          zIndex: 10,
          transitionDuration: ".2s",
        }}
        disableGutters
      >
        <WhatsAppIcon sx={{ color: "white", width: "100%", height: "100%" }} />
      </Container>
    </Link>
  );
}
