import { Box, Modal, Typography } from "@mui/material";

export default function UnderDev() {
  return (
    <Modal open={true}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "CALC(100% - 48px)",
          maxWidth: "500px",
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          boxSizing: "border-box",
          px: { xs: 2, sm: 4 },
          py: 4,
        }}
      >
        <Typography variant="h4" align="center" sx={{ fontWeight: "bold" }}>
          Under Construction
        </Typography>
        <Typography variant="h6" align="center">
          The site is under construction and will be available by the start of February 2025
        </Typography>
      </Box>
    </Modal>
  );
}
